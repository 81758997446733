<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" @lessonCreated="lesson - created"
      :activeLesson="selectedLesson" />



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <h5> {{ lecturetitle }} </h5>

          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-button variant="primary" @click="newLesson">
                <span class="text-nowrap">Create Lesson</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>




      <div class="mx-2 mb-2">
        <b-row>



        </b-row>
      </div>

      <div>
        <draggable v-model="lectureList" class="list-group list-group-flush cursor-move" tag="ul">
          <transition-group type="transition" name="flip-list">
            <b-list-group-item v-for="listItem in lectureList" :key="listItem.id" tag="li">
              <div class="d-flex">

                <div md="6" class="ml-25 col-6">

                  <b-link
                    :to="{ name: 'course-lecturelessons-edit', params: { id: listItem.id, type: listItem.lessonType } }"
                    class="font-weight-bold d-block text-nowrap">
                    <b-avatar :text="(listItem.lessonorder + 1).toString()" />
                    {{ listItem.title }}

                  </b-link>

                </div>

                <div md="3" class="ml-25 col-3">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50"> {{ listItem.lessonType }} </span>



                </div>



                <div md="4" class="ml-25 col-3">


                  <b-link
                    :to="{ name: 'course-lecturelessons-edit', params: { id: listItem.id, type: listItem.lessonType } }"
                    class="font-weight-bold text-nowrap">
                    <b-button variant="flat-info">
                      <span class="align-middle ml-50">Edit</span>
                    </b-button>
                  </b-link>


                  <b-badge variant="light-danger" v-if="Boolean(listItem.deleted)">


                    Deleted
                  </b-badge>
                  <b-button variant="flat-danger" @click="deleteExam(listItem)">


                    <span class="align-middle ml-50">Delete</span>
                  </b-button>

                </div>




              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroupItem, BAvatar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, computed, WritableComputedRef } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './LectureLessonsListFilters.vue'
import useUsersList from './useLectureLessonsList'
import userStoreModule from '../lecturelessonsStoreModule'
import UserListAddNew from './LectureLessonsListAddNew.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  data() {
    return {
      selectedLesson: null,
    }
  },
  methods: {

    deleteExam(coursedata) {
      //console.log(coursedata);

      store.dispatch('apps-exam/deleteEhxam', coursedata)
        .then((response) => {

          var result = response.data.success;


          this.selectedCategory = null;
          this.localExam = {
            id: null,
            hasExactOccurDate: false,
            title: '',
            examType: "ONLINE",
            description: '',
            examDuration: 120,
            passLevel: 70,
            maxAttemptCount: 0,
            examLocation: '',
            examLocationRoom: '',
            examLocationSeat: '',
          };


          this.$emit('refetch-data')
          coursedata.deleted = true;



        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    }
    ,
    lessonCreated() {
      this.selectedLesson = null;
    },

    newLesson() {
      this.selectedLesson = {
        title: null,
        prefix: null,
        estimatedDurationMinutes: 5,
        order: 1,
        lessonType: null,


      }

      this.isAddNewUserSidebarActive = true;
    },
    editLesson(item) {
      this.localOptions = item;
    },


    getRoute(coursemode) {

      return "page-coursemode";
    }
  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    draggable,
    BListGroupItem,
  },
  setup() {
    const toast = useToast()
    const USER_APP_STORE_MODULE_NAME = 'course-lecture-lessons'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })




    const sessionsData = ref(null);

    const lecturetitle = ref(router.currentRoute.params.lecturetitle);

    const lectureid = ref(router.currentRoute.params.lectureid);
    const routecourseguid = ref(router.currentRoute.params.id);


    var activeId = ref(null);
    if (router.currentRoute.params.lectureid) {
      //console.log("using from route");
      activeId.value = router.currentRoute.params.lectureid;
    }
    else {
      //console.log("using from getter");
      var unit = store.getters['app/currentLecture'];
      activeId.value = unit;

    }

    store.commit('app/SETACTIVELECTURE', activeId.value)

    //console.log("incoming id: "+  activeId.value);





    const courseguidval = ref(router.currentRoute.params.id);
    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]



    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectures
    } = useUsersList(activeId)

    const lectureList = computed({
      get() {
        return lectures.value;

      },
      set(oldValue) {
        // set something
        //console.log("updated");
        lectures.value = oldValue;
        var newORders = [];

        var index = 0;

        lectures.value.forEach(element => {
          newORders.push({ id: element.id, order: index });
          index++;

        });
        //console.log(newORders);
        reOrderLessons(newORders);




      },
    });

    const reOrderLessons = (data) => {

      var postData = {};
      postData.act = "REORDER";
      postData.orders = data;


      postData.lectureid = router.currentRoute.params.lectureid;
      postData.courseguid = router.currentRoute.params.id;


      store
        .dispatch('course-lecture-lessons/reOrderLessons',
          postData,

        )
        .then(response => {

          refetchData();

        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    }
    refetchData();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      roleOptions,
      planOptions,
      statusOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectures,
      lectureList,
      lectureid,
      lecturetitle,
      routecourseguid,
      reOrderLessons
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
